import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export interface ResponseBase {
  result: string;
  errormsg?: string;
}

export const DEFAULT_API_PARAMS = {
  api_key: "k7YtGf3PmE2U",
  api_secret: "j5VaMy4BmrW",
};

const DEFAULT_BASE_PATH = "https://testadmin.tora-wifi.com/api";

const DEFAULT_PARAMS: AxiosRequestConfig = {
  baseURL: DEFAULT_BASE_PATH,
};

export function getApiService(): AxiosInstance {
  return axios.create(DEFAULT_PARAMS);
}
