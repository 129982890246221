
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonModal",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCloseModal(): void {
      this.$emit("update:isShow", false);
    },
  },
  emits: ["update:isShow"],
});
