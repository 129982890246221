import { useGlobalStore } from "@/stores/global";
import loginService, {
  UserLoginParams,
  UserLoginResponse,
} from "@/api/login.service";
import { AxiosError } from "axios";

export interface LoginStatus {
  member_id: number;
  member_token: string;
}

const KEY_NAME = {
  id: "loginId",
  token: "loginToken",
};

const loginUtil = {
  login(params: UserLoginParams): Promise<UserLoginResponse> {
    return loginService.userLogin(params).then(({ data: res }) => {
      if ((res.result !== "0000" && res.errormsg) || !res.data) {
        throw new AxiosError(res.errormsg);
      }
      sessionStorage.setItem(KEY_NAME.id, String(res.data.member_id));
      sessionStorage.setItem(KEY_NAME.token, res.data.member_token);
      useGlobalStore().logIn();
      return res;
    });
  },
  loggedIn(): boolean {
    return (
      !!sessionStorage.getItem(KEY_NAME.id) &&
      !!sessionStorage.getItem(KEY_NAME.token)
    );
  },
  getLoginStatus(): LoginStatus {
    const id = sessionStorage.getItem(KEY_NAME.id);
    const token = sessionStorage.getItem(KEY_NAME.token);
    return {
      member_id: id ? Number(id) : 0,
      member_token: token ? token : "",
    };
  },
  logout(): void {
    sessionStorage.removeItem(KEY_NAME.id);
    sessionStorage.removeItem(KEY_NAME.token);
    useGlobalStore().logOut();
  },
};
export default loginUtil;
