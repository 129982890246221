
import { defineComponent } from "vue";
import globalHeader from "./views/shared/header/Header.vue";
import globalFooter from "./views/shared/footer/Footer.vue";
import OnLoading from "@/views/shared/common/OnLoading.vue";
import { useGlobalStore } from "@/stores/global";
import { LocationQuery } from "vue-router";

import { DEFAULT_HEADER_SETTINGS as defaultHeader } from "@/assets/const/default-header-settings";

export default defineComponent({
  name: "app",
  components: {
    globalHeader,
    globalFooter,
    OnLoading,
  },
  data() {
    return {
      isLogin: false,
      username: null as string | null,
      password: null as string | null,
    };
  },
  mounted() {
    let isLogin = sessionStorage.getItem("isLogin");
    if (isLogin == "true") {
      this.isLogin = true;
    } else {
      this.openLoginPopup();
    }
    this.setMetaTags();
  },
  watch: {
    "$route.query": function (): void {
      const param: LocationQuery = this.$route.query;
      if (param.cpn_cd && !Array.isArray(param.cpn_cd)) {
        useGlobalStore().setCampaignCode(param.cpn_cd.substring(0, 100));
      }
      if (param.agc_cd && !Array.isArray(param.agc_cd)) {
        useGlobalStore().setAgencyCode(param.agc_cd.substring(0, 100));
      }
    },
    "$route.meta": function (): void {
      this.setMetaTags();
    },
  },
  methods: {
    openLoginPopup() {
      this.username = prompt("アカウントを入力してください:", "");
      if (this.username) {
        this.password = prompt("パスワードを入力してください:", "");
        if (this.password) {
          this.checkCredentials(this.username, this.password);
        } else {
          this.password = prompt("パスワードを入力してください:", "");
        }
      } else {
        this.username = prompt("アカウントを入力してください:", "");
      }
    },
    /* eslint-disable */
    checkCredentials(username: any, password: any) {
      // 这里应该是对账号密码的验证逻辑
      if (username === "w8htijzu" && password === "35edb8f8") {
        sessionStorage.setItem("isLogin", "true");
        this.isLogin = true;
      } else {
        this.openLoginPopup();
      }
    },
    setMetaTags() {
      this.setTitle();
      this.setDescription();
      this.setRobots();
    },
    setTitle(): void {
      const metaData = this.$route.meta.title;
      const title =
        metaData && typeof metaData === "string"
          ? metaData
          : defaultHeader.title;
      if (this.$route.name === "Home") {
        document.title = title;
        return;
      }
      document.title = title + defaultHeader.title_suffix;
    },
    setDescription(): void {
      const metaData = this.$route.meta.description;
      const description =
        metaData && typeof metaData === "string"
          ? metaData
          : defaultHeader.description;
      const elm = document.querySelector("meta[name='description']");
      if (elm instanceof Element) {
        elm.setAttribute("content", description);
      }
    },
    setRobots(): void {
      const robots = this.$route.meta.robots;
      const elm = document.querySelector("meta[name='robots']");
      if (!(elm instanceof Element)) return;
      if (robots && typeof robots === "string") {
        elm.setAttribute("content", robots);
        return;
      }
      elm.setAttribute("content", defaultHeader.robots);
    },
  },
});
