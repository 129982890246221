
import { defineComponent } from "vue";
import CommonModal from "@/views/shared/common/CommonModal.vue";
import orderService, { getProductParams } from "@/api/order.service";
import {
  AreaList,
  CountryList,
} from "@/views/order-form/order-form-interfaces";
import { AxiosError } from "axios";
import PriceListPullDown from "@/views/home/component/PriceListPullDown.vue";
import { SelectOptions } from "@/views/shared/form-parts/PullDownSelect.vue";
import Utility from "@/assets/common/scripts/utility";
import {
  MULTIPLE_PLANS,
  PlanList,
  AreaSegmentedCountries,
} from "@/assets/static-data/multiple-plans";
import { Product } from "@/views/order-form/order-form-interfaces";

interface RatePlanData {
  isFupModalShown: boolean;
  isMenuStandby: boolean;
  isLoading: boolean;
  pullDownOptions: {
    area: AreaList[];
    countries: CountryList[];
  };
  selectData: {
    travelPlan: "1" | "2";
    travelArea: string;
    multipleProduct: string;
    travelCountry: string;
  };
  receivedProductList: Product[];
  coverageModal: {
    isShow: boolean;
    name: string;
    list: string[] | AreaSegmentedCountries[];
  };
}

const MULTIPLE_PRODUCT_LIST = [
  {
    label: "世界周遊",
    value: "world",
  },
  {
    label: "欧州周遊",
    value: "europe",
  },
  {
    label: "アジア周遊",
    value: "asia",
  },
  {
    label: "北米周遊",
    value: "northAmerica",
  },
];

export default defineComponent({
  name: "ratePlan",
  components: {
    PriceListPullDown,
    CommonModal,
  },
  watch: {
    "selectData.travelCountry": function (newVal) {
      if (newVal === "") return;
      const params: getProductParams = {
        country_name1: newVal,
      };
      this.isLoading = true;
      orderService
        .getProductLists(params)
        .then(({ data: res }) => {
          if (res.result !== "0000" && res.errormsg) {
            throw new AxiosError(res.errormsg);
          }
          if (!res.data) return;
          this.receivedProductList = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    travelPlanOptions(): SelectOptions[] {
      return [
        {
          label: "1ヵ国の渡航",
          value: "1",
        },
        {
          label: "2ヵ国以上の渡航",
          value: "2",
        },
      ];
    },
    areaOptions(): SelectOptions[] {
      return Utility.getAreaSelectOptions(this.pullDownOptions.area);
    },
    filteredCountryOptions(): SelectOptions[] {
      return Utility.getFilteredCountryOptions(
        this.selectData.travelArea,
        this.pullDownOptions.countries
      );
    },
    multipleProductOptions(): SelectOptions[] {
      return MULTIPLE_PRODUCT_LIST;
    },
    isSinglePlanSelected(): boolean {
      return Number(this.selectData.travelPlan) === 1;
    },
    isAreaSelected(): boolean {
      return this.selectData.travelArea !== "";
    },
    isCountrySelected(): boolean {
      return this.selectData.travelCountry !== "";
    },
    isMultiProductSelected(): boolean {
      return this.selectData.multipleProduct !== "";
    },
    multiProductLabel(): string {
      const result = MULTIPLE_PRODUCT_LIST.find((elm) => {
        return elm.value === this.selectData.multipleProduct;
      });
      return result ? result.label : "";
    },
    singleProductList(): Product[] {
      return this.receivedProductList.filter((elm) => {
        return (
          (Number(elm.pro_typeflg) === 0 || Number(elm.pro_typeflg) === 1) &&
          Number(elm.pro_aroundflg) === 0
        );
      });
    },
    multiProductList(): PlanList[] {
      return (
        MULTIPLE_PLANS[this.selectData.multipleProduct] ?? ([] as PlanList[])
      );
    },
    segmentedCountryList(): AreaSegmentedCountries[] {
      if (typeof this.coverageModal.list[0] !== "string") {
        return this.coverageModal.list as AreaSegmentedCountries[];
      }
      return [];
    },
  },
  created() {
    this.preparePullDownOptions();
  },
  data(): RatePlanData {
    return {
      isFupModalShown: false,
      isMenuStandby: false,
      isLoading: false,
      pullDownOptions: {
        area: [] as AreaList[],
        countries: [] as CountryList[],
      },
      selectData: {
        travelPlan: "1",
        travelArea: "",
        multipleProduct: "",
        travelCountry: "",
      },
      receivedProductList: [] as Product[],
      coverageModal: {
        isShow: false,
        name: "",
        list: [],
      },
    };
  },
  methods: {
    onClickFupModal(): void {
      this.isFupModalShown = true;
    },
    preparePullDownOptions(): void {
      orderService.getFormOptions().then(({ data: res }) => {
        if (res.result !== "0000" && res.errormsg) {
          throw new AxiosError(res.errormsg);
        }
        if (!res.data) return;
        this.pullDownOptions.area = res.data.areaList;
        this.pullDownOptions.countries = res.data.countryList;
        this.isMenuStandby = true;
      });
    },
    onChangeTravelPlan(): void {
      this.selectData.travelArea =
        this.selectData.travelCountry =
        this.selectData.multipleProduct =
          "";
    },
    onChangeTravelArea(): void {
      this.selectData.travelCountry = "";
      this.receivedProductList = [];
    },
    getProductPriceLabel(product: Product): string {
      if (Number(product.pro_typeflg) === 0) return "期間";
      return "日";
    },
    getProductPrice(product: Product): string {
      const price = Number(product.pro_taxflg)
        ? product.pro_taxprice
        : product.pro_price;
      return price.toLocaleString();
    },
    onClickCoverageModal(
      name: string,
      list: string[] | AreaSegmentedCountries[]
    ): void {
      this.coverageModal.name = name;
      this.coverageModal.list = list;
      this.coverageModal.isShow = true;
    },
  },
});
