import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/common/modal_close.svg'


const _withScopeId = n => (_pushScopeId("data-v-ad9ae8d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-modal-header" }
const _hoisted_2 = { class: "common-modal-body" }
const _hoisted_3 = { class: "common-modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "common-modal-bg",
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onCloseModal && _ctx.onCloseModal(...args)))
  }, [
    _createElementVNode("div", {
      class: "common-modal",
      onClick: _cache[2] || (_cache[2] = 
        (ev) => {
          ev.stopPropagation();
        }
      )
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "click to close",
          class: "close-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseModal && _ctx.onCloseModal(...args)))
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCloseModal && _ctx.onCloseModal(...args))),
          class: "close"
        }, "閉じる")
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.isShow]
  ])
}