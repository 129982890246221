import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import loginUtil from "@/assets/common/scripts/loginUtil";
import { useGlobalStore } from "@/stores/global";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title: "【公式】海外WiFiレンタルは「PENTACT WiFi（ペンタクトWiFi）」",
      description:
        "海外WiFiレンタルはPENTACT WiFi（ペンタクトWiFi）」におまかせ！シンプルなサービス設計で料金も業界最安値！海外旅行や出張でWiFiを心配せずにお使いいただくための安心で豊富なサービスが満載。",
    },
  },
  {
    path: "/order-form",
    children: [
      {
        name: "OrderForm",
        path: "",
        component: () => import("../views/order-form/OrderForm.vue"),
        meta: {
          title: "ペンタクトWiFiのお申込みフォーム",
          description:
            "ペンタクトWiFiのお申込みは簡単4ステップ！3分でお申込みが完了します。",
        },
      },
      {
        name: "OrderCompleted",
        path: "order-completed",
        component: () =>
          import("../views/order-form/order-completed/OrderCompleted.vue"),
        meta: {
          title: "お申し込み完了",
          description:
            "ペンタクトWiFiのお申込みは簡単4ステップ！3分でお申込みが完了します。",
          robots: "noindex, nofollow",
        },
      },
    ],
  },
  {
    path: "/contact",
    children: [
      {
        name: "ContactForm",
        path: "",
        component: () => import("../views/contact-form/ContactForm.vue"),
        meta: {
          title: "お問合せ",
          description:
            "ペンタクトWiFIをお申込みいただく前に気になることがございましたら、お気軽にお問い合わせください。",
        },
      },
      {
        name: "MessageSent",
        path: "message-sent",
        component: () => import("../views/contact-form/MessageSent.vue"),
        meta: {
          title: "お問合せの送信完了",
          robots: "noindex, nofollow",
        },
      },
    ],
  },
  {
    name: "MyPage",
    path: "/my-page",
    component: () => import("../views/my-page/MyPage.vue"),
    redirect: "/my-page/order-history",
    children: [
      {
        name: "OrderHistory",
        path: "order-history",
        component: () =>
          import("../views/my-page/order-history/OrderHistory.vue"),
        meta: {
          title: "お申込み履歴/領収書のダウンロード",
          description:
            "領収書のダウンロードはこちらから可能です。もちろんお申込み履歴もご確認いただけます。",
        },
      },
      {
        name: "CustomerProfile",
        path: "customer-profile",
        component: () =>
          import("../views/my-page/customer-profile/CustomerProfile.vue"),
        meta: {
          title: "お客様情報の登録/変更",
          description: "お客様情報の登録と変更はこちらから。",
        },
      },
      {
        name: "ServiceWithdrawal",
        path: "service-withdrawal",
        component: () =>
          import("../views/my-page/service-withdrawal/ServiceWithdrawal.vue"),
        meta: {
          title: "退会の申請",
        },
      },
    ],
  },
  {
    name: "LoginEntry",
    path: "/login",
    component: () => import("../views/login/LoginEntry.vue"),
    meta: {
      title: "マイページログイン",
      description:
        "ペンタクトWiFiのマイページログインはこちらから。領収書のダウンロードや履歴情報の確認もできます。",
    },
  },
  {
    path: "/reset-password",
    children: [
      {
        name: "ResetPassword",
        path: "",
        component: () => import("../views/reset-password/ResetPassword.vue"),
        meta: {
          title: "パスワード変更申請",
        },
      },
      {
        name: "NewPasswordEntry",
        path: "new-pw-entry",
        component: () => import("../views/reset-password/NewPasswordEntry.vue"),
        meta: {
          title: "パスワードの再設定",
          robots: "noindex, nofollow",
        },
      },
      {
        name: "ResetCompleted",
        path: "reset-completed",
        component: () => import("../views/reset-password/ResetCompleted.vue"),
        meta: {
          title: "パスワード更新完了",
          robots: "noindex, nofollow",
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

function routerInit() {
  const routerInstance = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
      if (to.hash) {
        return { el: to.hash };
      }
      return { top: 0 };
    },
  });
  routerInstance.beforeEach(() => {
    if (loginUtil.loggedIn()) {
      useGlobalStore().logIn();
      return;
    }
    useGlobalStore().logOut();
  });
  return routerInstance;
}

export default routerInit();
